.login{
    /* border: 1px solid black; */
    height: 72vh;
}
.logIn-card{
    border-radius: 10px;
}

.logo-color{
    background-color: #043c9c !important;
    color:white
}
.icon-color{
    color: #043c9c !important;
}

.side-bar-color{
    /* background-color: #043c9c !important; */
    background-color: #f1f1ff !important;
    
}
.option-color{
    color: white !important;
}