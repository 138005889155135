
.page-hero {
    background-color: #212121;
    height: 100vh;
}
.video-content{
    min-width: 500px;
    background-color: #b8d9e5;
}

.hidden {
    display: none;
}

.holder--video {
    position: relative;
    overflow: hidden;
    margin-bottom: 0.5rem;
    min-height: 100vh;
}

.holder--video-remote{
    position: absolute;
    height: 100vh;
    width: 100%;
    right: 0;
    /*background-color: #c3d1e3;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: center;*/
}

.holder--video-me {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    width: 150px;
    height: 150px;
    overflow: hidden;
    border: 3px solid #fff;
    border-radius: 15px;
    background: #efefef;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-style: italic;
}

.options {
    top:  85vh;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    margin: 0 300px;
}

.holder--options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 5px 0;
  }
  
  .holder--options-option {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-color: none;
    outline: none;
    border: 0px;
    background: none;
    color: #fff;
    padding: 0 30px;
  }
  
  .holder--options-option i {
    background: #fff;
    border-radius: 50%;
    font-size: 1rem;
    width: 40px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    color: #555;
    -webkit-box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
            box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  
  .holder--options-option.active i {
    color: #00f5d4;
  }

  .remote-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 30%;
  }
  .remote-info img {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 50%);
  }
  .remote-info p {
    width: 80%;
    margin-top: 12px;
    text-align: justify;
    color: #9d9d9d;
  }

  @media screen and (max-width: 375px) {
    .video-content {
        min-width: 375px;
    }
    .options {
        margin: 0 190px;
    }
    .holder--video{
        margin-bottom: unset;
    }
    .holder--video-me {
        width: 98px;
        height: 100px;
    }
  }

  @media screen and (min-width: 376px) {
    .video-content {
        min-width: 390px;
    }
    .options {
        margin: 0 209px;
    }
    .holder--video{
        margin-bottom: unset;
    }
    .holder--video-me {
        width: 105px;
        height: 115px;
    }
  }

  @media screen and (min-width: 400px) {
    .video-content {
        min-width: 400px;
    }
    .options {
        margin: 0 209px;
    }
    .holder--video{
        margin-bottom: unset;
    }
    .holder--video-me {
        width: 95px;
        height: 105px;
    }
  }

  @media screen and (min-width: 768px) {
    .video-content {
        min-width: 550px;
    }
    .options {
        margin: 0 300px;
    }
    .holder--video{
        margin-bottom: unset;
    }
    .holder--video-me {
        width: 125px;
        height: 130px;
    }
  }

  @media screen and (min-width: 1200px) {
    .options {
        margin: 0 380px;
    }
  }

  @media screen and (min-width: 1400px) {
    .options {
        margin: 0 680px;
    }
  }